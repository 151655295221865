<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="{ name: 'PropertyBuilding' }"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Energiewaardes
    </h2>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="editEnergyValues"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      class="tw-max-w-xl"
      @submit="submit"
    >
      <h2 class="tw-mt-8">{{ epcString }}</h2>
      <FormulateInput
        v-if="isNewlyBuilt && isFlanders"
        type="checkbox"
        name="epb_in_draft"
        label="EPB in opmaak"
      />
      <div v-show="!isFlanders || !isNewlyBuilt || (isNewlyBuilt && !values.epb_in_draft)">
        <span v-show="isFlanders && isNewlyBuilt">Onderstaande velden moeten enkel worden ingevuld wanneer het EPB beschikbaar is (niet in opmaak)</span>
        <FormulateInput
          type="number"
          name="epc_kwhm"
          :label="`${epcString}-kengetal (kWh / m² / jaar)`"
          :placeholder="`${epcString}-kengetal (kWh / m² / jaar)`"
          validation="bail|number|min:0|max:2000"
          step="any"
          min="0"
        />
        <FormulateInput
          v-show="isFlanders"
          type="number"
          name="e_value"
          label="E-peil"
          placeholder="E-peil"
          validation="bail|number|min:0"
          step="any"
          min="0"
        />
        <FormulateInput
          type="select"
          name="epc_label"
          :label="`${epcString}-label`"
          :placeholder="`Selecteer ${epcString}-label`"
          :options="epcLabels"
        />
        <FormulateInput
          type="date"
          name="epc_date"
          :label="`${epcString}-opstellingsdatum`"
          placeholder="YYYY-MM-DD"
          validation='optional|date:YYYY-MM-DD'
        />
        <FormulateInput
          type="text"
          name="epc_number"
          label="Unieke code (UC)"
          placeholder="Unieke code (UC)"
        />
        <template v-if="isFlanders">
          <h2 class="tw-mt-8">EPC kNR</h2>
          <FormulateInput
            type="select"
            name="epc_knr"
            label="EPC-label kNR"
            placeholder="Selecteer EPC-label kNR"
            :options="epcLabels"
          />
          <FormulateInput
            type="date"
            name="epc_date_knr"
            label="EPC kNR-opstellingsdatum"
            placeholder="YYYY-MM-DD"
            validation='optional|date:YYYY-MM-DD'
          />
          <FormulateInput
            type="text"
            name="epc_number_knr"
            label="Unieke code kNR (UC)"
            placeholder="Unieke code kNR (UC)"
          />
          <h2 class="tw-mt-8">EPC NR</h2>
          <FormulateInput
            type="select"
            name="epc_nr"
            label="EPC-label NR"
            placeholder="Selecteer EPC-label NR"
            :options="epcLabelsNr"
          />
          <FormulateInput
            type="date"
            name="epc_date_nr"
            label="EPC NR-opstellingsdatum"
            placeholder="YYYY-MM-DD"
            validation='optional|date:YYYY-MM-DD'
          />
          <FormulateInput
            type="text"
            name="epc_number_nr"
            label="Unieke code NR (UC)"
            placeholder="Unieke code NR (UC)"
          />
          <fieldset class="tw-border-none tw-p-0">
          <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">
            Aandeel hernieuwbare energie
          </legend>
            <FormulateInput
              type="checkbox"
              name="renewable_energy_share_applicable_but_undefined"
              label="Op EPC NR staat dat aandeel hernieuwbare energie onbepaald is"
            />
            <FormulateInput
              v-show="showRenewableEnergyShare"
              type="number"
              name="renewable_energy_share"
              label="%"
              placeholder="Aandeel hernieuwbare energie (percentage)"
              validation="bail|number|min:0|max:100"
              step="any"
              min="0"
              max="100"
            />
          </fieldset>
        </template>
      </div>
      <FormulateErrors />
      <div class="tw-flex tw-flex-row tw-space-x-4">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :input-class="['tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
        <FormulateInput
          type="button"
          :input-class="['tw-bg-error tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
          @click="goBack"
        >
          <i class="fa fa-times tw-mr-2"/> Annuleren
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPropertyEnergy, updatePropertyEnergy } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'PropertyEnergyValuesEdit',
  data () {
    return {
      values: {}
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),

    propertyId () {
      return this.$route.params.id
    },
    property () {
      return this.getPropertyById(this.propertyId)
    },
    isFlanders () {
      return this.property?.region === 0
    },
    isBrussels () {
      return this.property?.region === 1
    },
    isNewlyBuilt () {
      return this.property.is_newly_built
    },
    epcString () {
      return this.isBrussels || this.isNewlyBuilt ? 'EPB' : 'EPC'
    },
    epcLabels () {
      const labelsFlanders = {
        0: 'Onbekend',
        1: 'A+',
        2: 'A',
        3: 'B',
        4: 'C',
        5: 'D',
        6: 'E',
        7: 'F'
      }
      const labelsBrussels = {
        0: 'Onbekend',
        2: 'A',
        3: 'B',
        4: 'C',
        5: 'D',
        6: 'E',
        7: 'F',
        8: 'G'
      }
      return this.isFlanders ? labelsFlanders : labelsBrussels
    },
    epcLabelsNr () {
      return {
        0: 'Onbekend',
        2: 'A',
        3: 'B',
        4: 'C',
        5: 'D',
        6: 'E',
        7: 'F',
        8: 'G',
        9: 'X'
      }
    },
    showRenewableEnergyShare () {
      return !this.values.renewable_energy_share_applicable_but_undefined
    }
  },
  created () {
    this.loadEnergyData()
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'PropertyBuilding' })
    },
    async loadEnergyData () {
      try {
        const response = await getPropertyEnergy(this.propertyId)
        this.values = response.data
        return response
      } catch (error) {
        errorModal('Fout bij het laden van de energiegegevens, probeer het opnieuw.')
      }
    },
    async submit (data) {
      try {
        const response = await updatePropertyEnergy(this.propertyId, data)
        successModal('De wijzigingen aan de energiewaardes zijn succesvol opgeslagen')
        this.goBack()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'editEnergyValues')
        errorModal('Er ging iets fout bij het wijzigen van de energiegegevens van dit pand. Gelieve nogmaals te proberen.')
      }
    }
  }
}
</script>
